<template>
  <div class="contact">
    <h1>{{ $t("contact.title") }}</h1>

    <div class="contact-cols">
      <div class="contact-form" ref="contactForm">
        <h2>{{ $t("contact.form") }}</h2>

        <div class="form-group">
          <label for="name">{{ $t("contact.form-name") }}</label>
          <input type="text"
            id="name"
            name="name"
            :class="{'error': nameRequired}"
            :placeholder="$t('contact.form-name-placeholder')" 
            v-model="name" />
          <p v-if="nameRequired">{{ $t("contact.form-required") }}</p>
        </div>

        <div class="form-group">
          <label for="mail">{{ $t("contact.form-email") }}</label>
          <input type="text"
            id="mail"
            name="mail"
            :class="{'error': mailRequired}"
            :placeholder="$t('contact.form-email-placeholder')"
            v-model="mail" />
          <p v-if="mailRequired">{{ $t("contact.form-required") }}</p>
          <p v-if="mailIncorrect">{{ $t("contact.form-incorrect-mail") }}</p>
        </div>

        <div class="form-group">
          <label for="message">{{ $t("contact.form-message") }}</label>
          <textarea name="message"
            id="message"
            cols="30"
            rows="8"
            :class="{'error': messageRequired}"
            :placeholder="$t('contact.form-message-placeholder')"
            v-model="message"></textarea>
          <p v-if="messageRequired">{{ $t("contact.form-required") }}</p>
        </div>

        <div class="form-group form-group-send">
          <button @click="send">{{ $t("contact.form-send") }}</button>
          <p class="message-sent" v-if="messageSent">{{ $t("contact.message-sent") }}</p>
        </div>
      </div>
      <div class="contact-map">
        <GoogleMap
          :api-key="apiKey"
          :style="`width: 100%; height: ${mapHeight}px`"
          :center="casaPosition"
          :zoom="11"
          style="border-radius: 0.5rem; overflow: hidden">
          <Marker :options="{position: casaPosition, icon: {url: casaMarker}}" />
        </GoogleMap>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, onMounted } from 'vue'
  import { GoogleMap, Marker } from 'vue3-google-map'
  import casaMarker from '@/assets/images/marker-house.png'

  export default {
    name: "Contact",
    components: { GoogleMap, Marker },
    setup() {
      const nameRequired = ref(false)
      const mailRequired = ref(false)
      const messageRequired = ref(false)
      const mailIncorrect = ref(false)
      const messageSent = ref(false)
      const name = ref("")
      const mail = ref("")
      const message = ref("")
      const casaPosition = { lat: 39.39487, lng: -9.03945 }
      const contactForm = ref(null)
      const mapHeight = ref()
      const apiKey = ref(process.env.VUE_APP_GMAPS_API_KEY)

      onMounted(() => mapHeight.value = contactForm.value.clientHeight)

      const send = () => {
        nameRequired.value = !name.value
        mailRequired.value = !mail.value
        messageRequired.value = !message.value
        mailIncorrect.value = !!mail.value && !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail.value.trim()))

        if (!nameRequired.value && !mailRequired.value && !messageRequired.value && !mailIncorrect.value) {
          Email.send({
            SecureToken: "465534d4-7b19-4a57-9e37-1d5f3f0662a8",
            To: "lvleignel@orange.fr",
            From: mail.value,
            Subject: `Message de Casa Portugal de la part de ${name.value.trim()}`,
            Body: message.value
          }).then(() => {
            messageSent.value = true
            name.value = ""
            mail.value = ""
            message.value = ""
          })
        }
      }

      return { 
        nameRequired,
        mailRequired,
        mailIncorrect,
        messageRequired,
        name,
        mail,
        message,
        send,
        messageSent,
        contactForm,
        mapHeight,
        casaPosition,
        casaMarker,
        apiKey,
      }
    }
  }
</script>

<style lang="scss" scoped>
  .contact {
    color: var(--contrast-color);
    padding: 1rem;

    &-back {
      cursor: pointer;
    }

    h1 {
      text-align: center;
      color: var(--main-color-accent)
    }

    &-cols {
      display: flex;
      align-items: center;

      & > div {
        padding: 0 2rem;
        width: 50%;
      }
    }

    &-form {
      margin-top: 2rem;
      width: 40%;

      .form-group {
        margin-bottom: 1rem;
        width: 100%;

        p {
          margin-top: 0;
          color: var(--color-form-error);
        }

        .message-sent {
          color: var(--color-form-success);
        }

        label, input, textarea {
          display: block;
          width: 100%;
          resize: none;
        }

        input, textarea {
          border: 2px solid var(--main-color-accent);
          padding: 0.5rem;
          box-sizing: border-box;

          &.error {
            border: 2px solid var(--color-form-error);
          }
        }

        &.form-group-send {
          text-align: right;
        }
      }

      button {
        background: var(--main-color-accent);
        color: var(--contrast-color);
        padding: 0.5rem;
        border: none;
        cursor: pointer;
      }
    }
  }

  @include mobile {
    .contact {
      &-cols {
        flex-direction: column;
        
        & > div {
          margin: auto;
          box-sizing: border-box;
          width: 100%;
        }
      }
    }
  }
</style>